import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import "./style.css";
import { Next } from "react-bootstrap/esm/PageItem";
import { ExportReactCSV } from './ExportReactCSV';


export default function Reservation({ accessType }) {

  var obj_company_detail = "";
  const lastWeekDate = moment().subtract(7, 'days');
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [lgShow1, setLgShow1] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [adjustmentTypeShw, setAdjustmentTypeShw] = useState('');
  const [adjustmentType, setAdjustmentType] = useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const [selectedRateCode, setSelectedRateCode] = useState([]);
  const [allRateCodeData, setAllRateCodeData] = useState([]);
  const [selectedOTA, setSelectedOTA] = useState([]);
  const [allOTAData, setAllOTAData] = useState([]);
  const [selectedCarClass, setSelectedCarClass] = useState("All");
  const [allCarClassData, setAllCarClassData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [allDaysData, setAllDaysData] = useState([]);
  const [minLenOfRental, setMinLenOfRental] = useState('');
  const [maxLenOfRental, setMaxLenOfRental] = useState('');
  const [typeIncDec, setTypeIncDec] = useState('');
  const [valueAdj, setValueAdj] = useState('');
  const [typeAmtPerc, setTypeAmtPerc] = useState('');
  const [rateCodeAll, setRateCodeAll] = useState(false);
  const [otaAll, setOTAAll] = useState(false);
  const [carClassAll, setCarClassAll] = useState(false);
  const [adjustmentsDetails, setAdjustmentsDetails] = useState([]);
  const [activeFlag, setActiveFlag] = useState(false);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [columnData, setColumnData] = useState([]);
  const [rateCodeAccess, setRateCodeAccess] = useState('Yes');
  const [oTAAccess, setOTAAccess] = useState('Yes');
  const [daysApplicableAccess, setDaysApplicableAccess] = useState('Yes');
  const [carClassAccess, setCarClassAccess] = useState('Yes');
  const [adjTypeAccess, setAdjTypeAccess] = useState('All');
  const [errorAlert, setErrorAlert] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [fetchClicked, setFetchClicked] = useState(false);
  const [rowDataForPDF, setRowDataForPDF] = useState([])
  const [basedOn, setBasedOn] = useState("Booked Date");
  const [bookingStatus, setBookingStatus] = useState("All");
  const [hashOTAIDVsName, setHashOTAIDVsName] = useState({});
  const [loading, setLoading] = useState(false)
  const [accountType, setAccountType] = useState("")
  const [reservation, setReservation] = useState("")
  const [rateCodeVsNameHash, setRateCodeVsNameHash] = useState({})


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser)
    if (foundUser.role === 'SuperAdmin') {
      setRateCodeAccess("Yes");
      setOTAAccess("Yes");
      setDaysApplicableAccess("Yes");
      setCarClassAccess("Yes");
      setAdjTypeAccess("All");
    }
    else {
      setRateCodeAccess(foundUser.access['Adjustment_Rate Code Selection']);
      setOTAAccess(foundUser.access['Adjustment_OTA Selection']);
      setDaysApplicableAccess(foundUser.access['Adjustment_Days Applicable Selection']);
      setCarClassAccess(foundUser.access['Adjustment_Car Class Selection']);
      setAdjTypeAccess(foundUser.access['Adjustment_Type of Adjustment/ Promotion / Blackout']);
    }

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['Reservations_Account Access'] + "*"
        + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);

        });
    }



  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setSelectedCarClass("All")
      setAllCarClassData([]);
      setAllLocationData([]);
      setErrorAlert("");
      setLgShow1(false);
      setFetchClicked(false);
      setLocationName("");
      setFromDate(lastWeekDate);
      setToDate(new Date());
      setLocationDetail([]);


      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      const company_type = obj_company_detail.account_type;
      setcompany_id(company_id_temp);
      setAccountType(company_type)


      axios.get(baseURL + "/get_company_location/" + company_id_temp)
        .then((res) => {

          let allTemp = res.data.res;


          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              let tempArr = [];
              let tempObj = {};
              for (let i = 0; i < allTemp.length; i = i + 4) {
                let tempArr2 = [];
                for (let j = i; j < i + 4; j++) {
                  if (allTemp[j] !== undefined) {
                    tempArr2.push(allTemp[j].location_name);
                    tempObj[allTemp[j].location_name] = false;
                  }
                }
                tempArr.push(tempArr2);
              }

              setAllLocationData(tempArr);
              setSelectedLocation(tempObj);
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              let tempArr = [];
              let tempObj = {};
              for (let i = 0; i < allTemp.length; i = i + 4) {
                let tempArr2 = [];
                for (let j = i; j < i + 4; j++) {
                  if (allTemp[j] !== undefined) {
                    tempArr2.push(allTemp[j].location_name);
                    tempObj[allTemp[j].location_name] = false;
                  }
                }
                tempArr.push(tempArr2);
              }

              setAllLocationData(tempArr);
              setSelectedLocation(tempObj);
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {

                if (allTemp.length > 0) {

                  let tempArr = [];
                  let tempObj = {};

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id])

                  for (let i = 0; i < allTemp.length; i = i + 4) {
                    let tempArr2 = [];
                    for (let j = i; j < i + 4; j++) {
                      if (allTemp[j] !== undefined && data.data[0].location_allocated[allTemp[j].location_id]) {
                        tempArr2.push(allTemp[j].location_name);
                        tempObj[allTemp[j].location_name] = false;
                      }
                    }
                    tempArr.push(tempArr2);
                  }

                  setAllLocationData(tempArr);
                  setSelectedLocation(tempObj);
                }
              })
          }


        }).catch((e) => { });


      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + company_id_temp, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {

              allTemp.push({ location_id: "all_locations", location_name: 'All Locations' })
              setLocationDetail(allTemp);

            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              allTemp.push({ location_id: "all_locations", location_name: 'All Locations' })
              setLocationDetail(allTemp);

            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                  allTemp.push({ location_id: "all_locations", location_name: 'All Locations' })
                  setLocationDetail(allTemp);


                }

              }
              )
          }
        });
      axios.get(baseURL + "/get_reservation_sources_all/" + company_id_temp)
        .then((res) => {
          const allTemp = res.data;
          let hash_OTA_IDVsName = {}
          if (allTemp.length > 0) {

            for (let i = 0; i < allTemp.length; i++) {
              hash_OTA_IDVsName[allTemp[i]._id] = allTemp[i].ota_name
            }
          }
          setHashOTAIDVsName(hash_OTA_IDVsName);
        }
        ).catch((e) => { });

      
        axios.get(baseURL + "/get_rate_codes_name_from_code")
        .then((res) => {
          const allTemp = res.data;
          setRateCodeVsNameHash(allTemp)
        }
        ).catch((e) => { });




    }//if condition ends

  }, [companyName]);


  useEffect(() => {
    if (locationName && locationDetail) {


      let temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      // setLocationId(temp_location_id);
      axios.get(baseURL + "/get_existing_car_classes_for_location/" + temp_location_id)
        .then((res1) => {

          let all_cars = res1.data
          let all_cars_arr = [{ 'value': 'All', 'label': 'All' }]
          for (let i = 0; i < all_cars.length; i++) {

            all_cars_arr.push({ 'value': all_cars[i].SIPP_code, 'label': all_cars[i].SIPP_code })
          }

          setAllCarClassData(all_cars_arr);

        }).catch((e) => { });

    }
  }, [locationName])


  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));


  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };



  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };

  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (

    // { value: value.location_name, label: value.location_name }
    { value: value.location_name, 
      label: value.location_id == 'all_locations' ? value.location_name : value.location_name != undefined && value.location_name + " - " + value.location_id.toString().trim().split("-")[1] 
    }
  ));



  const handleInputChangeCarClass = characterEntered => {
    setSelectedCarClass(characterEntered.value);
  };

  //Option for Location name
  const CarClassOptions = allCarClassData.map((value) => (

    { value: value.value, label: value.value }
  ));



  // Handle Submit Starts
  const handleFetch = (e) => {

    e.preventDefault();

    if (companyName === "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }

    if (locationName === "") {
      Swal.fire('Please select an location to proceed ahead');
      return;
    }
    if (fromDate === "") {
      Swal.fire('Please select From Date to proceed ahead');
      return;
    }
    if (toDate === "") {
      Swal.fire('Please select To Date to proceed ahead');
      return;
    }
    if (allCarClassData.length < 1) {
      Swal.fire('No Car Class available for this location');
      return;
    }

    if (basedOn === '') {
      Swal.fire('Please select one Based On Option');
      return;
    }
    if (bookingStatus === '') {
      Swal.fire('Please select one Booking Status Option');
      return;
    }


    const d = new Date();
    const id = d.getTime().toString();

    //location into 1D array
    let location_arr = [];
    let keyArr1 = Object.keys(selectedLocation);
    for (let i = 0; i < keyArr1.length; i++) {
      if (selectedLocation[keyArr1[i]])
        location_arr.push(keyArr1[i]);
    }

   
    setLoading(true);
    //API call to fetch Data
    axios.post(baseURL + "/get_reservations", {
      'account_id': company_id.toString(),
      'location_name': locationName,
      // 'from_date': fromDate, // previous one
      'from_date': new Date(fromDate).toDateString(), // new one
      // 'to_date': toDate, // previous one
      'to_date': new Date(toDate).toDateString(), // new one
      'based_on': basedOn,
      'booking_status': bookingStatus,
      'selected_car_class': selectedCarClass
    })
      .then((res) => {

        let rowsData = [];
        const myDataSet = res.data;
        let row_data_pdf = []
        let count = 0;
        for (var index = 0; index < res.data.length; index++) {

          let row_data_pdf_temp = [];
          let rowItem = {};
          const val = myDataSet[index];
          
          // console.log("----------val-----------");
          // console.log(val)

          rowItem["Serial_No"] = ++count;
          rowItem["Routes_Reservation_No"] = val.Routes_Reservation_No
          rowItem["PNR_RO_Number_Reservation_No"] = val.PNR_RO_Number_Reservation_No
          rowItem["Pickup_Location"] = val.Pickup_Location
          rowItem["DropOff_Location"] = val.DropOff_Location
          // rowItem["DropOff_Date_Time"] = formatDate_withTime(new Date(val.DropOff_Date_Time))
          rowItem["Pickup_Date_Time"] = val.Pickup_Date_Time.slice(0, -3)
          rowItem["DropOff_Date_Time"] = val.DropOff_Date_Time.slice(0, -3)
          rowItem["Booking_Date_Time"] = val.Booking_Date_Time.slice(0, -3)
          rowItem["Reservation_Source"] = hashOTAIDVsName[val.OTA]
          // rowItem["Booking_Date_Time"] = moment1(val.Booking_Date_Time).tz(val.Booking_Time_zone).format(); 
          rowItem["Status_Valid_Cancelled_No_Show"] = val.Status_Valid_Cancelled_No_Show
          rowItem["Cancelled_Date_Time"] = (val.Cancelled_Date_Time === undefined || val.Cancelled_Date_Time === "") ? "-" : val.Cancelled_Date_Time.slice(0, -3)
          rowItem["SIPP_Code"] = val.SIPP_Code
          rowItem["Rate_Code"] = val.Rate_Code
          rowItem["Rate_Code_Name"] = rateCodeVsNameHash[val.Rate_Code] === undefined ?  val.Rate_Code : rateCodeVsNameHash[val.Rate_Code]
          rowItem["Currency"] = val.Currency
          rowItem["length_of_rental"] = val.length_of_rental
          rowItem["IATA"] = val.IATA == undefined ? "-" : val.IATA
          rowItem["name"] = val.First_Name + " " + val.Last_Name
          rowItem["Email_ID"] = (loggedInUserDetail.role === 'SuperAdmin'  || loggedInUserDetail.account_type.account_type === 'Corporate' || loggedInUserDetail.userEmail == 'azarae@alliedcarrentalpr.com' || ["894978","898996","302906"].includes(loggedInUserDetail.account_id)) ?  val.Email_ID : ''
          rowItem["Phone_No"] = (loggedInUserDetail.role === 'SuperAdmin' || loggedInUserDetail.account_type.account_type === 'Corporate' || loggedInUserDetail.userEmail == 'azarae@alliedcarrentalpr.com'|| ["305681"].includes(loggedInUserDetail.account_id)) ?   val.Phone_No : ""
          rowItem["Final_Price"] = ((val.Total_Price) * 1 + 1 * val.Extras_Base_Value * 1 + 1 * val.Tax_on_Extras).toFixed(2)
          rowItem["Total_Price_of_car"] = (val.Total_Price).toFixed(2)
          rowItem["Base_Price_of_car"] = (val.Base_Price_rate_table).toFixed(2)
          rowItem["Applicable_Rate"] = (val.Car_Price).toFixed(2)
          rowItem["Tax_on_Car_Price_Value"] = (val.Tax_on_Car_Price_Value).toFixed(2)
          rowItem["Total_Price_Extras"] = (val.Extras_Base_Value * 1 + 1 * val.Tax_on_Extras).toFixed(2)
          rowItem["Extras_Base_Value"] = (val.Extras_Base_Value).toFixed(2)
          rowItem["Tax_on_Extras"] = (val.Tax_on_Extras).toFixed(2)
          rowItem["Tax_on_Car_Price_Details_Breakup"] = val.Tax_on_Car_Price_Details_Breakup
          rowItem["Extras_Details"] = val.Tax_on_Extras_Details


          rowsData.push(rowItem);

          //for pdf
          row_data_pdf_temp = [
            rowItem["Serial_No"], rowItem["Routes_Reservation_No"], rowItem["PNR_RO_Number_Reservation_No"], rowItem["Pickup_Date_Time"],
            rowItem["Pickup_Location"], rowItem["DropOff_Date_Time"], rowItem["DropOff_Location"], rowItem["Booking_Date_Time"],
            rowItem["Reservation_Source"], rowItem["Status_Valid_Cancelled_No_Show"], rowItem["Cancelled_Date_Time"], rowItem["SIPP_Code"],
            rowItem["Rate_Code"], rowItem["Currency"], rowItem["length_of_rental"], rowItem["IATA"], rowItem["name"], 
            (loggedInUserDetail.role === 'SuperAdmin' || loggedInUserDetail.account_type.account_type === 'Corporate') ? rowItem["Email_ID"] : "", (loggedInUserDetail.role === 'SuperAdmin' || loggedInUserDetail.account_type.account_type === 'Corporate' || loggedInUserDetail.account_id === '305681') ? rowItem["Phone_No"] : "",
            rowItem["Final_Price"], rowItem["Total_Price_of_car"], rowItem["Base_Price_of_car"], rowItem["Applicable_Rate"], 
            rowItem["Tax_on_Car_Price_Value"], rowItem["Total_Price_Extras"], rowItem["Extras_Base_Value"], rowItem["Tax_on_Extras"],
            rowItem["Tax_on_Car_Price_Details_Breakup"], rowItem["Extras_Details"]
          ]
          row_data_pdf.push(row_data_pdf_temp);

        }
        setAdjustmentsDetails(rowsData);
        // console.log("----row_data_pdf----")
        // console.log(row_data_pdf)
        setRowDataForPDF(row_data_pdf);

        setColumnData([
          {
            label: "Serial No.",
            field: "Serial_No",
            sort: "asc",
            width: 150,
          },
          {
            label: "Routes Reservation No.",
            field: "Routes_Reservation_No",
            sort: "asc",
            width: 200,
          },
          {
            label: "Pickup Location",
            field: "Pickup_Location",
            sort: "asc",
            width: 10,
          },
          {
            label: "DropOff Location",
            field: "DropOff_Location",
            sort: "asc",
            width: 100,
          },
          {
            label: "Pickup Date Time",
            field: "Pickup_Date_Time",
            sort: "asc",
            width: 100,
          },
          {
            label: "DropOff Date Time",
            field: "DropOff_Date_Time",
            sort: "asc",
            width: 100,
          },
          {
            label: "Reservation Source",
            field: "Reservation_Source",
            sort: "asc",
            width: 200,
          },
          {
            label: "Status",
            field: "Status_Valid_Cancelled_No_Show",
            sort: "asc",
            width: 100,
          },
          {
            label: "Cancelled Date Time",
            field: "Cancelled_Date_Time",
            sort: "asc",
            width: 100,
          },
          {
            label: "SIPP Code",
            field: "SIPP_Code",
            sort: "asc",
            width: 100,
          },
          {
            label: "Rate Code",
            field: "Rate_Code",
            sort: "asc",
            width: 100,
          },
          {
            label: "Length Of Rental",
            field: "length_of_rental",
            sort: "asc",
            width: 100,
          },
          {
            label: "Customer Name",
            field: "name",
            sort: "asc",
            width: 100,
          },
          {
            label: "Final Price",
            field: "Final_Price",
            sort: "asc",
            width: 100,
          },
          {
            label: "Details",
            field: "Details",
            sort: "asc",
            width: 100,
          },
        ]);


        setFetchClicked(true);
        setLoading(false);
      }).catch((e) => { });

  }//handle submit ends

  let categoryDetail = {
    // Serial_No
    "Customer Details": ["Serial_No", "name", "Email_ID", "Phone_No"],
    "Price, Taxes & Optional Services": [
      "Base_Price_of_car",
      "Applicable_Rate",
      "Tax_on_Car_Price_Value",
      "Total_Price_of_car",
      "Extras_Base_Value",
      "Tax_on_Extras",
      "Total_Price_Extras",
      "Final_Price",
    ],
    "Taxes & Optional Services Details":[
      'Tax_on_Car_Price_Details_Breakup',
      'Extras_Details'
    ],
    // "": [
    //   "Total_Price_of_car",
    //   "Base_Price_of_car",
    //   "Total_Price_Extras",
    //   "Extras_Base_Value",
    // ],
    "Reservation Details": [
      "Routes_Reservation_No",
      "PNR_RO_Number_Reservation_No",
      "Pickup_Location",
      "DropOff_Location",
      "Pickup_Date_Time",
      "DropOff_Date_Time",
      "Booking_Date_Time",
    ],
    "":["Status_Valid_Cancelled_No_Show",
    "Cancelled_Date_Time",
    "SIPP_Code",
    "Rate_Code",
    "Rate_Code_Name",
    "length_of_rental",
    "Reservation_Source",
    "IATA"
  ]
    // R: [
    //   "DropOff_Date_Time",
    //   "DropOff_Location",
    //   "Booking_Date_Time",
    //   "reservation_source",
    // ],
  };


  //Table Data
  const data = {
    columns: columnData,
    rows: adjustmentsDetails.map((el) => ({
      ...el,
      Details: (
        <button
          onClick={() => {
            setReservation(el);
            setLgShow1(true);
          }}
        >
          View
        </button>
      ),
    })),
  };

  
  //Export in PDF
  const handleExportPDF = (e) => {

    // e.preventDefault();
    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [24, 24]
    });

    const col = columnData.map((val) => val.label);
    const row = rowDataForPDF

    autoTable(doc, {
      head: [col],
      body: row
    });
    doc.save("Reservation.pdf");

  }



  return (
    <>
          <Modal
        size="lg"
        show={lgShow1}
        onHide={() => {
          setLgShow1(false);
          setReservation({});
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="modal-width"
        // style={{
        //   width: "1200px",
        // }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {reservation.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row gx-0" style={{ width: "100%" }}>
            {Object.entries(categoryDetail).map(([key, value]) => (
              <div className="col-12" style={{ width: "100%" }}>
                <h3>{key}</h3>
                {/* {value.map((ele) => (
                  <div className="row">
                    <div className="col-5">{ele.split("_").join(" ")}</div>
                    <div className="col-7">:{reservation[ele]}</div>
                  </div>
                ))} */}
                <div
                  style={{ width: "100%", overflowX: "auto" }}
                  className="removeScroll"
                >
                  <MDBDataTable
                    className="table-no-gutter"
                    paging={false}
                    bordered
                    displayEntries={false}
                    large={false}
                    data={{
                      rows: [
                        value.reduce(
                          (prev, next) => ({
                            ...prev,
                            [next]: reservation[next],
                            
                          }),
                          
                          {}
                        ),
                      ],
                      columns: value.map((ele) => ({
                        label: ele.split("_").join(" "),
                        field: ele,
                        sort: "asc",
                        width: 145
                      })),
                    }}
                    sortable={false}
                    responsiveLg={false}
                    noBottomColumns={true}
                    searching={false}
                    pagination={false}
                  />
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>

      <div className="row pt-3">
        {/* <div className="col-1"></div> */}
        <div className="col-11 mx-3">

          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name*
              </label>
            </div>

            <div className="col-md-3 mb-3">

              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                Location Name*
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <Select

                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                inputId="location_name"
                name="location_name"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}

              />
            </div>

          </div>{/* first row wrapper ends */}


          {companyName ? (<>
            {/* Third row */}
            <div className="row mt-3">
              <div className="col-12 mb-4 ">

                <div className="row mb-4">{/* First row wrapper modal starts */}
                  <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Filters : </span>
                </div>{/* First row wrapper modal ends */}


                {/* 3.2 Row */}
                <div className="row">
                  <div className="col-md-2 mb-3">
                    <label htmlFor="car_name" className="form-label">
                      Car Class*
                    </label>
                  </div>

                  <div className="col-md-3 mb-3">

                    <Select

                      value={{ value: selectedCarClass, label: (selectedCarClass ? selectedCarClass : "Select Car Class") }}
                      inputId="car_name"
                      name="car_name"
                      options={CarClassOptions}
                      onChange={handleInputChangeCarClass}
                    // defaultValue={{ value: 'All', label: "All" }}

                    />

                  </div>


                  <div className="col-md-1 mb-3"></div>

                  <div className="col-md-2 mb-3">
                    {/* <label htmlFor="location_name" className="form-label">
                      Location Name*
                    </label> */}
                  </div>
                  <div className="col-md-3 mb-3">
                    {/* <Select

                      value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                      inputId="location_name"
                      name="location_name"
                      options={locationNameOptions}
                      onChange={handleInputChangeLocationName}

                    /> */}
                  </div>

                </div>{/* 3.2 Row ends */}


                {/* 3.3 Row */}
                <div className="row mt-3">
                  <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Based On*
                    </label>
                  </div>

                  <div className="col-md-3 mb-3">

                    <select className="form-select" aria-label="Default select example" value={basedOn} onChange={(e) => setBasedOn(e.target.value)}>
                      <option hidden>Choose One</option>
                      <option value="Booked Date">Booked Date</option>
                      <option value="PickUp Date">PickUp Date</option>
                      <option value="DropOff Date">DropOff Date</option>
                    </select>

                  </div>


                  <div className="col-md-1 mb-3"></div>

                  <div className="col-md-2 mb-3">
                    <label htmlFor="location_name" className="form-label">
                      Booking Status*
                    </label>
                  </div>
                  <div className="col-md-3 mb-3">
                    <select className="form-select" aria-label="Default select example" value={bookingStatus} onChange={(e) => setBookingStatus(e.target.value)}>
                      <option hidden>Choose One</option>
                      <option value="All" >All</option>
                      <option value="Valid">Valid</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="No Show">No Show</option>
                      <option value="Not Qualified">Not Qualified</option>
                    </select>

                  </div>

                </div>{/* 3.3 Row ends */}

                {/* 3.3 Row */}
                <div className="row mt-3">
                  <div className="col-md-2 mb-3">
                    <label htmlFor="location_name" className="form-label">
                      From Date*
                    </label>
                  </div>
                  <div className="col-md-3 mb-3">
                    <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                      setFromDate(date);
                    }}

                      style={{ width: '100%', position: 'relative' }}
                      value={fromDate ? moment(new Date(fromDate)) : fromDate}

                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />
                  </div>
                  <div className="col-md-1 mb-3"></div>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="location_name" className="form-label">
                      To Date*
                    </label>
                  </div>
                  <div className="col-md-3 mb-3">
                    <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy'
                      onChange={(date, dateString) => setToDate(date)} style={{ width: '100%' }}
                      value={toDate ? moment(new Date(toDate)) : toDate}

                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />
                  </div>



                </div>{/* 3.3 Row ends */}






                <div className="row mb-3">{/* Twelveth row wrapper modal starts */}
                  <div className="col-md-2  mt-3"></div>

                  <div className="col-md-10 mt-3 d-flex justify-content-end">
                    {loading ?
                      <button className="btn btn-success" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                        Fetch
                      </button>
                      :
                      <button type="submit" className="btn btn-success" onClick={(e) => handleFetch(e)}>
                        Fetch
                      </button>
                    }

                  </div>
                </div>{/* Twelveth row wrapper modal ends */}


              </div>
            </div>{/* Third row wrapper ends */}
          </>) : ""}


          {/* Fourth Row*/}
          {
            (!fetchClicked) ? "" :
              <>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <h5 className="mb-3">Existing Reservation(s): </h5>
                    <MDBDataTable
                      bordered
                      large={false}
                      data={data}
                      sortable={false}
                      responsiveLg={false}
                      noBottomColumns={true}
                    />


                  </div>
                </div>

                
          <div className="col-md-10 mb-4 mt-4">
            {adjustmentsDetails.length > 0 && fetchClicked ? (<>
              <div className="row">
                <div className="col-md-4 mb-4 ">
                  <ExportReactCSV data={data} report_name={"Reservation"} />
                </div>
                {/* <div className="col-md-1 mb-4 "></div> */}
                <div className="col-md-4 mb-4 ">
                  <button type="button" className="btn btn-secondary" onClick={(e) => handleExportPDF(e)}>
                    Download Reservation in PDF
                  </button>
                </div>
              </div>
            </>
            )
              : ""
            }
          </div>

              </>
          }
          {/* Fourth row ends */}



        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
