import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navibar from './components/layout/Navibar';
import NavibarCollasped from './components/layout/NavibarCollasped';
import Sidebar from './components/layout/Sidebar';
import AccountSetup from './components/pages/company/Company'
import UserSetup from './components/pages/user/User'
import Dashboard from './components/pages/dashboard/Dashboard'
import AccountLocations from './components/pages/companyLocation/CompanyLocation'
import Policies from './components/pages/policies/Policies'
import LocationSetup from './components/pages/locationSetup/LocationSetup'
import BusinessHours from './components/pages/businessHours/BusinessHours'
import TaxSetup from './components/pages/taxSetup/TaxSetup'
import ExtrasSetup from './components/pages/extrasSetup/ExtrasSetup'
import OneWayRentalSetup from './components/pages/oneWayRentalSetup/OneWayRentalSetup'
import FleetDescription from "./components/pages/fleetDescription/FleetDescription"
import AccountRateCodeSetup from "./components/pages/accountRateCodeSetup/AccountRateCodeSetup"
import AccountRateRules from "./components/pages/accountRateRules/AccountRateRules"
import ReservationSourceSetup from "./components/pages/reservationSourceSetup/ReservationSourceSetup"
import RateCodeSetup from "./components/pages/rateCodeSetup/RateCodeSetup"
import MinimumRate from './components/pages/minimumRate/MinimumRate';
import RateUpdate from './components/pages/rateUpdate/RateUpdate';
import Adjustments from "./components/pages/adjustments/Adjustments"
import Promotions from "./components/pages/promotions/Promotions"
import Blackouts from "./components/pages/blackouts/Blackouts"
import UtilizationBasedAdjustment from "./components/pages/utilizationBasedAdjustment/UtilizationBasedAdjustment"
import UpdateInventory from "./components/pages/updateInventory/UpdateInventory";
import Login from "./components/pages/login/Login";
import RolesSetup from "./components/pages/rolesSetup/RolesSetup";
import ErrorCodes from "./components/pages/errorCodes/ErrorCodes";
import Cars from "./components/pages/cars/Cars";
import Reports from "./components/pages/reports/Reports";
import CapacityControl from "./components/pages/capacityControl/CapacityControl";
import Europcars from "./components/pages/europcars/Europcars";
import Reservations from './components/pages/reservations/Reservations';
import NoShow from './components/pages/noShow/NoShow';
import FetchConsole from './components/pages/fetchConsole/FetchConsole';
import RateID from './components/pages/rateID/RateID';
import EventLog from './components/pages/eventLog/EventLog';
import OptionalServicesStandard from './components/pages/optionalServicesStandard/OptionalServicesStandard';
import FleetStandard from './components/pages/fleetStandard/FleetStandard';
import RateSnapshot from './components/pages/rateSnapshot/RateSnapshot';
import OptionalServicesImages from './components/pages/optionalServicesImages/OptionalServicesImages';
import MyProfile from './components/pages/my_profile/MyProfile';
import AffiliateOTAMapping from './components/pages/affiliateOTAMapping/AffiliateOTAMapping';
import BankReconciliationReport from './components/pages/bankReconciliationReport/BankReconciliationReport';
import MultipleAccountsAdjustmentPromotion from './components/pages/MultipleAccountsAdjustmentPromotion/MultipleAccountsAdjustment&Promotion';
import CommissionScreen from './components/pages/commissionScreen/commissionScreen';
import CompetitorSetup from './components/pages/competitorSetup/CompetitorSetup';
import MinimumRateMapping from './components/pages/minimumRateMapping/MinimumRateMapping';
import EnableAutomation from './components/pages/enableAutomation/EnableAutomation';
import AutomationSetUp from './components/pages/automationSetUp/AutomationSetUp';
import CurrencyConverter from './components/pages/currencyConverter/CurrencyConverter';
import SupportScreen from './components/pages/supportScreen/SupportScreen';
import ViewBaseRates from './components/pages/viewBaseRates/ViewBaseRates';
import ReservationInsights from "./components/pages/ReservationInsights/ReservationInsights";
import CeilingRate from "./components/pages/ceilingRate/CeilingRate";
import PolicyExpedia from "./components/pages/policyExpedia/PolicyExpedia";
import DBRCleanUp from "./components/pages/dBRCleanUp/DBRCleanUp";
import InstantAutomationPro from './components/pages/instantAutomationPro/InstantAutomationPro'
import Competitors from './components/pages/competitors/Competitors'

import { baseURL } from './components/pages/backend_url';
import axios from 'axios';

function App() {

  const [collaspedFlag, setCollapsedFlag] = useState(false);
  const [sidebarColor, setSidebarColor] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [moduleDetail, setModuleDetail] = useState([]);
  const [moduleDetailHash, setModuleDetailHash] = useState({});


  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);

    axios.get(baseURL + "/get_modules")
      .then((res) => {

        const loggedInUser1 = sessionStorage.getItem("user_token");
        if (loggedInUser1) {

          setLoginSuccess(true);
        }

        let temp_hash = {};

        if (foundUser.role === 'Users') {
          res.data.push({
            "name": "MyProfile",
            "favourite": {
            }
          })
        }
        const temp_module_arr = res.data.map((val) => {

          let temp_mod_name = val.name.toString().trim();
          if (temp_mod_name.indexOf(' ') > -1) {
            let t1_split = temp_mod_name.split(" ");
            if (t1_split.length > 0) {
              temp_mod_name = '';
              for (let i1 = 0; i1 < t1_split.length; i1++) {

                temp_mod_name += t1_split[i1];
              }
            }
          }

          if (temp_mod_name === 'Account')
            temp_hash[temp_mod_name] = <AccountSetup />

          else if (temp_mod_name === 'User')
            temp_hash[temp_mod_name] = <UserSetup />

          else if (temp_mod_name === 'AccountLocations')
            temp_hash[temp_mod_name] = <AccountLocations />

          else if (temp_mod_name === 'Policies')
            temp_hash[temp_mod_name] = <Policies />

          else if (temp_mod_name === 'Location')
            temp_hash[temp_mod_name] = <LocationSetup />

          else if (temp_mod_name === 'BusinessHours')
            temp_hash[temp_mod_name] = <BusinessHours />

          else if (temp_mod_name === 'Tax')
            temp_hash[temp_mod_name] = <TaxSetup />

          else if (temp_mod_name === 'OptionalServices')
            temp_hash[temp_mod_name] = <ExtrasSetup />

          else if (temp_mod_name === 'OneWayRental')
            temp_hash[temp_mod_name] = <OneWayRentalSetup />

          else if (temp_mod_name === 'FleetDescription')
            temp_hash[temp_mod_name] = <FleetDescription />

          else if (temp_mod_name === 'AccountRateCode')
            temp_hash[temp_mod_name] = <AccountRateCodeSetup />

          else if (temp_mod_name === 'AccountRateRules')
            temp_hash[temp_mod_name] = <AccountRateRules />

          else if (temp_mod_name === 'ReservationSource')
            temp_hash[temp_mod_name] = <ReservationSourceSetup />

          else if (temp_mod_name === 'RateCode')
            temp_hash[temp_mod_name] = <RateCodeSetup />

          else if (temp_mod_name === 'MinimumRate')
            temp_hash[temp_mod_name] = <MinimumRate />

          else if (temp_mod_name === 'RateUpdate')
            temp_hash[temp_mod_name] = <RateUpdate />

          else if (temp_mod_name === 'Adjustment')
            temp_hash[temp_mod_name] = <Adjustments />

          else if (temp_mod_name === 'Promotion')
            temp_hash[temp_mod_name] = <Promotions />

          else if (temp_mod_name === 'Blackout')
            temp_hash[temp_mod_name] = <Blackouts />

          else if (temp_mod_name === 'UtilizationBasedAdjustment')
            temp_hash[temp_mod_name] = <UtilizationBasedAdjustment />

          else if (temp_mod_name === 'UpdateInventory')
            temp_hash[temp_mod_name] = <UpdateInventory />

          else if (temp_mod_name === 'AccessSetup')
            temp_hash[temp_mod_name] = <RolesSetup />

          else if (temp_mod_name === 'ErrorCodes')
            temp_hash[temp_mod_name] = <ErrorCodes />

          else if (temp_mod_name === 'Cars')
            temp_hash[temp_mod_name] = <Cars />

          else if (temp_mod_name === 'Reports')
            temp_hash[temp_mod_name] = <Reports />

          else if (temp_mod_name === 'CapacityControl')
            temp_hash[temp_mod_name] = <CapacityControl />

          else if (temp_mod_name === 'Europcars')
            temp_hash[temp_mod_name] = <Europcars />

          else if (temp_mod_name === 'Reservations')
            temp_hash[temp_mod_name] = <Reservations />

          else if (temp_mod_name === 'NoShow')
            temp_hash[temp_mod_name] = <NoShow />

          else if (temp_mod_name === 'FetchConsole')
            temp_hash[temp_mod_name] = <FetchConsole />

          else if (temp_mod_name === 'RateID')
            temp_hash[temp_mod_name] = <RateID />


          else if (temp_mod_name === 'EventLog')
            temp_hash[temp_mod_name] = <EventLog />

          else if (temp_mod_name === 'OptionalServicesStandard')
            temp_hash[temp_mod_name] = <OptionalServicesStandard />

          else if (temp_mod_name === 'FleetStandard')
            temp_hash[temp_mod_name] = <FleetStandard />

          else if (temp_mod_name === 'RateSnapshot')
            temp_hash[temp_mod_name] = <RateSnapshot />

          else if (temp_mod_name === 'OptionalServicesImages')
            temp_hash[temp_mod_name] = <OptionalServicesImages />

          else if (temp_mod_name === 'MyProfile')
            temp_hash[temp_mod_name] = <MyProfile />

          else if (temp_mod_name === 'AffiliateOTAMapping')
            temp_hash[temp_mod_name] = <AffiliateOTAMapping />

          else if (temp_mod_name === 'BankReconciliationReport')
            temp_hash[temp_mod_name] = <BankReconciliationReport />

          else if (temp_mod_name === 'MultipleAccountsAdjustment&Promotion')
            temp_hash[temp_mod_name] = <MultipleAccountsAdjustmentPromotion />
          
          else if (temp_mod_name === 'CommissionScreen')
            temp_hash[temp_mod_name] = <CommissionScreen />
          
            else if (temp_mod_name === 'CompetitorSetup')
            temp_hash[temp_mod_name] = <CompetitorSetup />

          else if (temp_mod_name === 'MinimumRateMapping')
            temp_hash[temp_mod_name] = <MinimumRateMapping />

          else if (temp_mod_name === 'EnableAutomation')
            temp_hash[temp_mod_name] = <EnableAutomation />

          else if (temp_mod_name === 'AutomationSetUp')
            temp_hash[temp_mod_name] = <AutomationSetUp />

          else if (temp_mod_name === 'CurrencyConverter')
          temp_hash[temp_mod_name] = <CurrencyConverter />

          else if (temp_mod_name === 'SupportScreen')
            temp_hash[temp_mod_name] = <SupportScreen />
          
          else if (temp_mod_name === 'ViewBaseRates')
          temp_hash[temp_mod_name] = <ViewBaseRates />
          
          else if (temp_mod_name === "ReservationInsights")
            temp_hash[temp_mod_name] = <ReservationInsights />;

          else if (temp_mod_name === "CeilingRate")
            temp_hash[temp_mod_name] = <CeilingRate />;
          
          else if (temp_mod_name === "PolicyExpedia")
            temp_hash[temp_mod_name] = <PolicyExpedia />;
            
          else if (temp_mod_name === "DBRCleanUp")
            temp_hash[temp_mod_name] = <DBRCleanUp />;

          else if (temp_mod_name === 'InstantAutomationPro')
            temp_hash[temp_mod_name] = <InstantAutomationPro />;
            
          else if (temp_mod_name === 'Competitors')
            temp_hash[temp_mod_name] = <Competitors />;
          return temp_mod_name;
        })



        if (foundUser.role === 'Users') {
          axios.post(baseURL + "/get_individual_user_details", { 'user_email': foundUser.userEmail })
            .then((res1) => {
              let accnt = foundUser.account_id
              if (foundUser.account_type.account_type != 'Corporate') {

                accnt = foundUser.account_type.affiliated_to_id
              }


              axios.post(baseURL + "/get_user_role_detail", { 'user_role': res1.data[0].selected_role, 'account_id': accnt.toString() })
                .then((res2) => {

                  res2.data[0].role_definition['MyProfile_Access Specifier'] = "Edit";

                  const tempMenuItems = temp_module_arr.filter((val) => {
                    if ((val === 'SupportScreen') || (val === 'ViewBaseRates' && (res2.data[0].role_definition["RateUpdate_Access Specifier"] === "Edit"
                    || res2.data[0].role_definition["RateUpdate_Access Specifier"] === "View")) || (res2.data[0].role_definition[val + "_Access Specifier"] === "Edit"
                      || res2.data[0].role_definition[val + "_Access Specifier"] === "View")) {
                      return val
                    }
                  })

                  setModuleDetailHash(temp_hash);
                  setModuleDetail(tempMenuItems);

                })
            })
        }
        else if (foundUser.role === 'SuperAdmin') {

          setModuleDetailHash(temp_hash);
          setModuleDetail(temp_module_arr);

        }

      })

  }, [])


  return (
    <>
      <Router>

        {!loginSuccess ? "" : (<>
          {collaspedFlag ? <NavibarCollasped setLoginSuccess={setLoginSuccess} /> : <Navibar setSidebarColor={setSidebarColor} sidebarColor={sidebarColor} setLoginSuccess={setLoginSuccess} />}

          <div className="customSidebar">
            <Sidebar setCollapsedFlag={setCollapsedFlag} sidebarColor={sidebarColor} />
          </div>
        </>)}
        <div className={!loginSuccess ? "" : collaspedFlag ? 'rightSideContentCollasped' : "rightSideContent"}>

          <Routes>
            {!loginSuccess ?

              <Route exact path="/" element={<Login setLoginSuccess={setLoginSuccess} />} />

              :
              (<>
                <Route exact path="/" element={<Dashboard />} />
                {
                  moduleDetail.length > 0 ? moduleDetail.map((val) => (
                    <Route exact path={"/" + val} element={moduleDetailHash[val]} />
                  )) : ""
                }

              </>)}
          </Routes>
        </div>


      </Router>
    </>
  );
}

export default App;
